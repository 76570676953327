@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

#root {
  position: relative;
}

.slick-slider {
  cursor: pointer;
}

.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
}

.slick-prev {
  left: -35px;
}

.slick-next {
  right: -35px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
  font-size: 30px;
}

.whatsapp-icon {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
